@import "bourbon";
@import "neat";

@font-face {
  font-family: Gotham;
  font-weight: 400;
  src: url(../fonts/Gotham-Light.otf);
}

@font-face {
  font-family: Gotham;
  font-weight: 500;
  src: url(../fonts/Gotham-Medium.otf);
}

@font-face {
  font-family: Gotham;
  font-weight: 600;
  src: url(../fonts/Gotham-Bold.otf);
}

$custom-grid--fourth: (
  columns: 4,
  gutter: 2%
);

@mixin aspect-ratio($width, $height) {
    position: absolute;
    &:before{
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .quiz-item-label {
        position: absolute;
        left: 0;
        right: 0;
    }
}

body {
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial;
}

.breadcrumb {
  .counter {
    display: none;
    position: absolute;
    background-color: #cdcdcd;
    padding: 0;
    margin: 0;
    padding: 4px;
    border-radius: 9px;
    z-index: 1;

    @media screen and (max-width: 500px)  {
      right: 5px;
      height: 18px;
      top: calc(100vh - 24px);

      &-step {
        margin-right: 5px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    @media screen and (min-width: 500px)  {
      right: 5px;
      width: 18px;
      top: 50vh;
      margin-top: -32px;
    }

    &-step {
      width: 10px;
      height: 10px;
      background-color: white;
      display: inline-block;
      float: left;
      margin-bottom: 5px;
      border-radius: 50%;

      &:last-child {
        margin-bottom: 0px;
      }

      &.active {
        background-color: #ea434e;
      }
    }
  }
}

.page-leaving {
  opacity: 0;

  &-headline {
    position: absolute;
    z-index: 2;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 70px;
    letter-spacing: -5px;
    line-height: 60px;
    margin: 0;
    top: 45%;
    transform: translateY(-50%);

    @media screen and (min-width: 800px)  {
      font-size: 90px;
    }

    &-span {
      font-size: 40px;
      letter-spacing: -2px;
      display: block;
    }
  }
}

.fullscreen-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}


.main {
  height: 100vh;

  .quiz {
    @include grid-container;
    opacity: 0;
    position: relative;

    &-step {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media screen and (min-width: 500px)  {
        padding-right: 18px;
      }

      @media screen and (min-width: 800px)  {
        padding-right: 12px;
      }
    }

    &-column {
      @include grid-column(1, $custom-grid--fourth);
      display: inline-block;
      position: relative;
      height: 100%;

      &:nth-child(1) {
        .quiz-item {
          @include aspect-ratio(43,78);
        }
      }

      &:nth-child(2) {
        .quiz-item {
          @include aspect-ratio(43,93);
        }
      }

      &:nth-child(3) {
        .quiz-item {
          @include aspect-ratio(43,93);
        }
      }

      &:nth-child(4) {
        .quiz-item {
          @include aspect-ratio(43,78);
        }
      }

      &:nth-child(even) {
        .quiz-item {
          top: 0;
          transform: translateY(-100%);
        }

        .quiz-item-label {
          bottom: 0;
          background: linear-gradient(0, rgba(41, 45, 47, 0.2), transparent);
        }
      }

      &:nth-child(odd) {
        .quiz-item {
          bottom: 0;
          transform: translateY(100%);
        }

        .quiz-item-label {
          background: linear-gradient(0, transparent, rgba(41, 45, 47, 0.2));
          top: 0;
        }
      }
    }

    &-item {
      display: inline-block;
      filter: grayscale(80%);
      cursor: pointer;
      width: 100%;

      &::before {
        background-position: center;
        background-size: cover;
        background-color: #f5f5f5;
      }

      &-label {
        color: white;
        text-align: center;
        font-size: 14px;
        line-height: 1.3;
        padding-top: 0.6em;
        padding-bottom: 0.6em;
        text-shadow: 1px 1px 12px rgba(0, 0, 0, .07);
        font-weight: bold;
        text-transform: uppercase;
        position: absolute;

        @media screen and (min-width: 500px)  {
          font-size: 16px;
        }

        @media screen and (min-width: 700px)  {
          font-size: 22px;
        }

        @media screen and (min-width: 900px)  {
          font-size: 26px;
        }

        @media screen and (min-width: 1000px)  {
          font-size: 30px;
        }

        @media screen and (min-width: 1200px)  {
          font-size: 34px;
        }
      }
    }
  }
}

.hint-rotate-phone {
  display: none;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
  padding: 10px;
  border-radius: 10px;
  z-index: 10;

  &-image {
    background-image: url(../img/picto-rotate-phone.png);
    background-size: 100%;
    width: 100px;
    height: 100px;
  }

  @media all and (orientation: portrait) {
    display: none;
  }
}

.page-thankyou,
.page-maintenance,
.page-onboarding,
.page-submit {
  background-color: #fdfdfd;
  background-image: url(../img/background-form.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: -7px;
  height: 100%;
  position: absolute;
  width: 100%;
  opacity: 0;

  @media screen and (min-width: 400px)  {
    background-position-y: -10px;
  }

  &-form {
    width: calc(100% - 20px);
    float: left;
    margin-left: 10px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px 10px;
    margin-top: 32px;
    position: relative;
    border: 8px solid white;

    .verboz {
      display:none;

      p {
        font-size: 110%;
      }

      & > p:first-child {
        margin-top: 90px;
      }

      & > p:last-child {
        margin-bottom: -20px;
      }
    }


    @media screen and (min-width: 400px)  {
      @include grid-column(12);
      @include grid-push(0);
      padding: 30px 20px;
    }

    @media screen and (min-width: 460px)  {
      margin-top: 38px;
    }

    @media screen and (min-width: 500px)  {
      @include grid-column(10);
      @include grid-push(1);
      padding: 40px;
      margin-top: 50px;
    }

    @media screen and (min-width: 600px)  {
      margin-top: 70px;
    }

    @media screen and (min-width: 700px)  {
      @include grid-column(8);
      @include grid-push(2);
      margin-top: 80px;
    }

    @media screen and (min-width: 800px)  {
      margin-top: 120px;
    }

    @media screen and (min-width: 960px)  {
      margin-top: 140px;
      .verboz {display: inline;}
    }

    @media screen and (min-width: 1400px)  {
      @include grid-column(6);
      @include grid-push(3);
      margin-top: 160px;
    }

    &-logo {
      width: 50px;
      position: absolute;
      top: 0;
      margin-left: -25px;
      margin-top: -25px;

      @media screen and (min-width: 700px)  {
        width: 70px;
        margin-left: -35px;
        margin-top: -35px;
      }

      @media screen and (min-width: 800px)  {
        width: 100px;
        margin-left: -50px;
        margin-top: -50px;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.2;
      margin-top: 30px;
      margin-bottom: 0;
      color: #0d1e2e;

      @media screen and (min-width: 400px)  {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 2px;
      }

      @media screen and (min-width: 500px)  {
        margin-top: 10px;
        margin-bottom: 5px;
      }

      @media screen and (min-width: 600px)  {
        margin-top: 15px;
        margin-bottom: 10px;
      }

      @media screen and (min-width: 700px)  {
        margin-top: 40px;
        margin-bottom: 15px;
        font-size: 30px;
      }

      @media screen and (min-width: 800px)  {
        margin-top: 60px;
      }

      &-em {
        @media screen and (min-width: 600px)  {
          display: block;
        }
      }
    }

    &-subtitle {
      display: none;

      @media screen and (min-width: 900px)  {
        display: block;
        margin: 10px 0 0;
        color: #0d1e2e;
        font-size: 18px;
      }
    }

    &-label {
      display: block;
      line-height: 2;
    }

    &-input {
      border: 1px solid grey;
      padding: 8px 12px;
      display: block;
      width: 100%;
      line-height: 1.5;

      &:active,
      &:focus {
        outline: 2px solid #1f3454;
      }
    }

    &-cta,
    &-submit {
      color: white;
      background-color: #111d2f;
      cursor: pointer;
      border: 0;
      display: block;
      width: 100%;
      text-decoration: none;
      padding: 12px 18px 10px 18px;
      margin-top: 10px;
      font-weight: 500;
      text-transform: uppercase;
      transition: background-color .3s ease;

      @media screen and (min-width: 700px)  {
        margin-top: 20px;
      }

      &:hover {
        background-color: #1f3454;
      }
    }
  }
}

.page-maintenance {
  opacity: 1;
  background-image: url(../img/background-maintenance.jpg);
  background-size: cover;
  background-position-x: center;

  &-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 60px;
    margin-bottom: 15px;
    color: #0d1e2e;
    text-align: center;
    padding: 0 20px;
    margin-top: 100px;
  }
}

.page-thankyou {
  opacity: 1;

  &.skewer {
    background-image: none;
    background-color: #eeeeee;
  }

  &-form {
    &.full,
    &.skewer {
      @media screen and (min-width: 700px)  {
        @include grid-column(8);
        @include grid-push(2);
        // margin-top: 120px;
      }

      @media screen and (min-width: 1000px)  {
        @include grid-column(6);
        @include grid-push(3);
        // margin-top: 160px;
      }
    }

    &.skewer {
      margin-top: 10px;

      @media screen and (min-width: 400px)  {
        margin-top: 20px;
      }

      @media screen and (min-width: 500px)  {
        @include grid-column(12);
        @include grid-push(0);
      }

      @media screen and (min-width: 700px)  {
        margin-top: 40px;
        @include grid-column(10);
        @include grid-push(1);
      }

      @media screen and (min-width: 900px)  {
        margin-top: 60px;
        @include grid-column(8);
        @include grid-push(2);
        // margin-top: 160px;
      }

      .page-thankyou-form {
        &-logo {
          width: 250px;
          max-width: 100%;
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          margin-left: 0;
          margin-top: 0;

          @media screen and (min-width: 700px)  {
            width: 350px;
          }
        }

        &-actions {
          margin-top: 10px;

          @media screen and (min-width: 400px)  {
            margin-top: 20px;
          }

          @media screen and (min-width: 700px)  {
            margin-top: 30px;
          }

          @media screen and (min-width: 700px)  {
            margin-top: 60px;
          }

          &-img {
            display: none;

            @media screen and (min-width: 500px)  {
              display: inline-block;
              max-width: 100%;
              width: 40%;
            }

            @media screen and (min-width: 700px)  {
              width: 60%;
            }
          }

          &-left,
          &-right {
            width: 100%;

            @media screen and (min-width: 400px)  {
              float: left;
              width: 45%;
            }

            @media screen and (min-width: 500px)  {
              width: 43%;
            }
          }

          &-right {
            margin-top: 10px;

            @media screen and (min-width: 400px)  {
              margin-left: 10%;
              margin-top: 0;
            }

            @media screen and (min-width: 500px)  {
              margin-left: 14%;
            }
          }
        }

        &-cta {
          background-color: #fc0089;
          border-radius: 10px;

          @media screen and (min-width: 400px)  {
            margin-top: 10px;
          }

          &:hover {
            background-color: #fe6631;
          }
        }
      }
    }

    &-actions {
      margin-top: 40px;

      @media screen and (min-width: 400px)  {
        margin-top: 30px;
      }

      @media screen and (min-width: 500px)  {
        margin-top: 20px;
      }

      @media screen and (min-width: 700px)  {
        margin-top: 60px;
      }

      @media screen and (min-width: 1000px)  {
        margin-top: 80px;
      }

      &-left,
      &-right {
        width: 100%;

        @media screen and (min-width: 700px)  {
          float: left;
          width: 49%;
        }
      }

      &-right {
        margin-top: 5px;

        @media screen and (min-width: 400px)  {
          margin-top: 10px;
        }

        @media screen and (min-width: 500px)  {
          margin-top: 15px;
        }

        @media screen and (min-width: 700px)  {
          margin-left: 2%;
          margin-top: 0;
        }
      }
    }

    &-cta {
      margin-top: 0;
    }
  }
}

.page-onboarding {
  background-image: url(../img/background-home.jpg);
  overflow: auto;

  &-form {
    &-title {
      font-weight: 500;
    }

    hr {
      width: 40%;
      margin: 40px auto;
    }
  }

  &-headline {
    position: fixed;
    width: 100%;
    text-align: center;
    margin-top: 15%;
    font-size: 14px;

    @media screen and (min-width: 500px)  {
      font-size: 16px;
      margin-top: 20%;
    }

    @media screen and (min-width: 800px)  {
      font-size: 20px;
    }
  }
}
